#archive {
  font-family: "BasisGrotesqueMono";
}

#archive h1,
#archive .artist-content {
  font-family: "BasisGrotesqueBold";
  text-transform: uppercase;
}

@media (min-width: 769px) {
  #archive {
    display: flex;
    flex-direction: row;
    height: 90vh;
    width: 100%;
  }
  #archive.artist .icon-chevron-right,
  #archive.artist .icon-chevron-left {
    display: none;
  }
  #archive .main-content {
    border-right: solid 1px black;
    width: 92vw;
  }
  #archive .archive-slider {
    width: 100%;
  }
  #archive .archive-item {
    align-items: flex-start;
    border-left: solid 1px black;
    background: #f9f5ec;
    display: flex;
    cursor: pointer;
    justify-content: center;
    height: 30vh;
    position: relative;
    width: 100%;
    transition: all 250ms cubic-bezier(0.645, 0.045, 0.355, 1); /* easeInOutCubic */
    transition-timing-function: cubic-bezier(
      0.645,
      0.045,
      0.355,
      1
    ); /* easeInOutCubic */
  }
  #archive .archive-item.even-item {
    background: #f4e5d2;
  }
  #archive .archive-item:hover {
    background: #ff4513;
  }
  #archive .archive-item .full-background-cover {
    border-radius: 50%;
    filter: grayscale(100%);
    height: 65%;
    margin: 0 auto;
    margin-top: 2vmin;
    width: 80%;
  }

  #archive .layer {
    align-items: flex-end;
    /* background: rgba(249, 245, 236, 0.3); */
    bottom: 0;
    font-weight: 100;
    display: flex;
    left: 0;
    position: absolute;
    justify-content: center;
    padding: 2vmin;
    text-align: center;
    right: 0;
    top: 0;
    transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1); /* easeInOutCubic */
    transition-timing-function: cubic-bezier(
      0.645,
      0.045,
      0.355,
      1
    ); /* easeInOutCubic */
  }
  #archive .even-item .layer {
    /* background: rgba(244, 229, 210, 0.35); */
  }
  #archive .layer:hover {
    background: rgba(255, 69, 19, 0.5);
  }
  #archive .layer:hover p {
    font-family: "GranvilleLightItalic";
    font-size: 3.5vmin !important;
  }
  #archive .layer p {
    font-size: 3vmin;
    line-height: 3.5vmin;
    margin: 0;
    text-transform: uppercase;
  }
  #archive .layer:hover p {
    color: white;
  }

  #archive .archive-control {
    align-items: center;
    border-bottom: solid 1px black;
    border-top: solid 1px black;
    display: flex;
    height: 30vh;
    justify-content: space-between;
    padding: 2vmin 6%;
    width: 100%;
  }
  #archive .archive-control h1 {
    font-size: 8vw;
    text-transform: uppercase;
    margin: 0 3vmin;
  }
  #archive .archive-title {
    align-items: center;
    display: flex;
    font-size: 8vmin;
  }
  #archive .archive-button {
    border: solid 1px black;
    cursor: pointer;
    border-radius: 4vmin;
    margin-bottom: 1vmin;
    padding: 0.5vmin 2vmin;
    transition: all 250ms cubic-bezier(0.645, 0.045, 0.355, 1); /* easeInOutCubic */
    transition-timing-function: cubic-bezier(
      0.645,
      0.045,
      0.355,
      1
    ); /* easeInOutCubic */
  }
  #archive .archive-button:hover {
    background: #ff4513;
  }
  #archive .archive-button span {
    font-size: 2vmin;
    line-height: 0;
  }

  #archive .artist-slider,
  #archive .artist-slider .full-background-cover {
    height: 90vh;
    position: relative;
    width: 45vw;
  }
  #archive .artist-slider .slider-buttons {
    bottom: 0;
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100%;
  }
  #archive .artist-slider .slider-button-container {
    box-sizing: border-box;
    border-top: solid 1px black;
    width: 50%;
  }
  #archive .artist-slider .slider-button {
    border-radius: 50%;
    background-color: #f4e5d2;
    align-items: center;
    display: flex;
    overflow: hidden;
    justify-content: center;
    height: 100%;
    text-align: center;
    cursor: pointer;
    transition: all 250ms cubic-bezier(0.645, 0.045, 0.355, 1); /* easeInOutCubic */
    transition-timing-function: cubic-bezier(
      0.645,
      0.045,
      0.355,
      1
    ); /* easeInOutCubic */
  }
  #archive .artist-slider .slider-button-container:nth-child(odd) {
    border-right: solid 1px black;
  }
  #archive .artist-slider .slider-button span {
    font-size: 8vw;
    height: 15vh;
    margin-top: 2vw;
    overflow: hidden;
  }
  #archive .artist-slider .slider-button:hover {
    background: #ff4513;
  }

  #archive .artist-content {
    height: 90vh;
    padding: 2vmin 7%;
    position: relative;
    width: 65vw;
  }
  #archive .artist-content h1 {
    font-size: 6vw;
    text-transform: uppercase;
  }
  #archive .artist-paragraphs {
    font-size: 1.4vmin;
  }
  #archive .artist-paragraphs p{
    font-family: 'BasisGrotesqueMedium' !important;
    text-transform: none !important;
  }
  #archive .back-btn {
    cursor: pointer;
    font-size: 5vw;
    top: 3vh;
    left: 2%;
    position: absolute;
  }
  #archive .back-btn:hover {
    color: #ff4513;
  }

  #archive .scroll-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 8vw;
  }

  #archive .scroll-section .scroll-btn {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 30vh;
    position: relative;
    width: 100%;
    cursor: pointer;
  }
  #archive .scroll-section .scroll-btn img {
    height: 20%;
    width: 50%;
  }
  #archive .scroll-section .scroll-btn .hover {
    /* display: none; */
    position: absolute;
    height: 8vw;
    right: 10%;
    width: 8vw;
    pointer-events: none;
  }
  #archive .scroll-section .scroll-btn:hover .hover {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.7);
  }
}

@media (max-width: 768px) {
  #archive {
    height: 100vh;
    border-bottom: solid 1px black;
    width: 100vw;
    overflow: hidden;
  }
  #archive .scroll-section {
    display: none;
  }
  #archive .main-content {
    height: 100%;
  }
  #archive .archive-slider {
    height: 33%;
    box-sizing: border-box;
  }
  #archive .alice-carousel {
    background: red;
    height: 100%;
  }
  #archive .archive-item {
    align-items: flex-start;
    border-left: solid 1px black;
    background: #f9f5ec;
    display: flex;
    cursor: pointer;
    justify-content: center;
    height: 100%;
    position: relative;
    width: 100%;
  }
  #archive .archive-item.even-item {
    background: #f4e5d2;
    transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1); /* easeInOutCubic */
    transition-timing-function: cubic-bezier(
      0.645,
      0.045,
      0.355,
      1
    ); /* easeInOutCubic */
  }
  #archive .archive-item:hover {
    background: #ff4513;
  }
  #archive .archive-item .full-background-cover {
    border-radius: 50%;
    filter: grayscale(100%);
    height: 65%;
    margin: 0 auto;
    margin-top: 2vmin;
    width: 80%;
  }
  #archive .archive-slider .slick-slider,
  #archive .archive-slider .slick-list,
  #archive .archive-slider .slick-track,
  #archive .archive-slider .slick-slide > div {
    height: 100%;
  }
  #archive .layer {
    align-items: flex-end;
    /* background: rgba(249, 245, 236, 0.3); */
    bottom: 0;
    font-weight: 100;
    display: flex;
    left: 0;
    position: absolute;
    justify-content: center;
    padding: 2vmin;
    text-align: center;
    right: 0;
    top: 0;
  }
  #archive .even-item .layer {
    /* background: rgba(244, 229, 210, 0.35); */
  }
  #archive .layer:hover {
    /* background: rgba(255, 69, 19, 0.5); */
  }
  #archive .layer p {
    font-size: 4.5vmin;
    margin: 0;
    text-transform: uppercase;
    line-height: 4.5vmin;
  }
  #archive .layer:hover p {
    color: white;
  }
  #archive .slick-list {
    overflow: hidden;
    width: 100vw;
  }

  #archive .archive-control {
    align-items: center;
    border-bottom: solid 2px black;
    border-top: solid 2px black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 34%;
    padding: 4vmin 0;
  }
  #archive .archive-title {
    align-items: center;
    display: flex;
    font-size: 6.5vmin;
  }
  #archive .archive-title h1 {
    margin: 0 3vmin;
  }
  #archive .slider-buttons {
    display: flex;
    flex-direction: row-reverse;
  }
  #archive .archive-button {
    border-radius: 2vmin;
    border: solid 1px black;
    font-size: 5vmin;
    padding: 2vmin 6vmin;
    margin: 0 1vmin;
  }
  #archive.artist {
    display: flex;
    flex-direction: column;
  }
  #archive.artist .artist-slider {
    position: relative;
    height: 40%;
  }
  #archive.artist .artist-slider .full-background-cover {
    height: 100%;
  }
  #archive.artist .slider-buttons {
    bottom: 0;
    display: flex;
    flex-direction: row;
    font-size: 10vmin;
    position: absolute;
  }
  #archive.artist .icon-big-chevron-right,
  #archive.artist .icon-big-chevron-left {
    display: none;
  }
  #archive.artist .artist-slider .slider-button-container {
    background: rgba(0, 0, 0, 0.5);
    border: solid 1px white;
    color: #ff4513 !important;
    margin: 1vmin;
  }
  #archive.artist .artist-content {
    font-size: 4vmin;
    padding: 4vmin;
    height: 60%;
    overflow: auto;
  }
  #archive.artist .back-btn {
    font-size: 10vmin;
  }
  #archive.artist .artist-paragraphs {
    text-transform: none;
  }
}

.slick-slider.slick-initialized,
.slick-slider.slick-initialized * {
  line-height: 0;
}
