.header-container {
  display: flex;
  flex-direction: row;
}

.header {
  align-items: center;
  background: #ff4513;
  display: flex;
  flex-direction: row;
  font-family: "BasisGrotesqueMedium";
  font-size: 2vmin;
  justify-content: space-between;
  max-width: 100%;
  padding: 1vh 0;
  width: 100%;
}

.header-contet {
  align-items: center;
  display: flex;
}

.header-contet .logo {
  cursor: pointer;
  margin: 0 1.5vw;
}

.header .icon {
  color: #f9f5ec;
  margin-right: 1vmin;
}

.header .header-item {
  align-self: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 0 1.5vmin;
}

.header .header-item span,
.submenu span,
.header-social-networks .icon {
  transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
  /* easeInOutCubic */
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  /* easeInOutCubic */
}

.header .header-item.active,
.header .header-item > span:hover {
  color: whitesmoke;
}

.header nav {
  display: flex;
  flex-direction: row;
}

.header-social-networks a {
  text-decoration: none;
}

.header-social-networks .icon {
  color: black;
}

.header-social-networks .icon:hover {
  color: whitesmoke;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10vh 0 0 10vh;
  border-color: transparent transparent transparent #ff4513;
}

.triangle-bottom-right {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 10vh 10vh;
  border-color: transparent transparent #ff4513 transparent;
}

.triangle-top-right {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10vh 10vh 0;
  border-color: transparent #ff4513 transparent transparent;
}

.triangle-top-left {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10vh 10vh 0 0;
  border-color: #ff4513 transparent transparent transparent;
}

.triangle-bottom-left {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10vh 0 0 10vh;
  border-color: transparent transparent transparent #ff4513;
}

@media (max-width: 768px) {
  .submenu {
    display: none;
  }

  .header-container {
    height: 10%;
    position: fixed;
    width: 100%;
    z-index: 10000;
  }

  .header-contet {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .header .header-item {
    display: none;
  }

  .header .menu-icon {
    font-size: 8vmin;
    display: inline-block;
  }

  .header nav,
  .header .header-social-networks {
    display: none;
  }

  .triangle {
    display: none;
  }

  #side-menu {
    font-family: "BasisGrotesqueBold";
    color: #3b3b3c;
    background: #ff4513;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 4vmin;
    position: fixed;
    font-size: 9vmin;
    text-transform: uppercase;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    transform: translate(0, -100vh);
    transition: all 700ms cubic-bezier(0.165, 0.84, 0.44, 1);
    /* easeOutQuart */
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    /* easeOutQuart */
  }

  #side-menu.active {
    transform: translate(0, 0);
  }

  #side-menu .header-social-networks {
    font-size: 4vmin;
  }

  .header-social-networks .link {
    margin-right: 2vmin;
  }

  #side-menu .header-social-networks span {
    color: white;
    margin-right: 2vmin;
  }

  #side-menu .close-label {
    align-self: flex-end;
    color: #f9f5ec;
    font-size: 12vmin;
  }

  #side-menu .options {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding: 0 10%;
  }

  #side-menu .options .link {
    cursor: pointer;
    color: #f9f5ec;
    margin: 10px 0;
  }

  #side-menu .logo {
    object-fit: contain;
    width: 100px;
    margin-bottom: 20px;
    position: absolute;
    left: 10px;
    top: 10px;
  }

  #side-menu .logout .icon {
    font-size: 30px;
    position: absolute;
    left: -5px;
    top: -5px;
  }

  #side-menu .logout {
    padding-left: 30px;
    position: relative;
    font-size: 4vmin;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
  }

  #side-menu .submenu {
    display: flex;
    font-size: 4.3vmin;
    font-size: 3.8vmin;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }

  #side-menu .submenu span {
    margin-bottom: 0.5rem;
  }

  .sidebar-nav-icon{
    display: none;
  }
  #sidebar-menu {
    display: none;
  }
}

@media (min-width: 1401px) {
  .header .header-social-networks {
    display: flex;
  }

  #side-menu {
    display: none;
  }

  .submenu {
    background-color: #ff4513;
    border-top: solid 1px black;
    border-bottom: solid 0px black;
    /* display: none; */
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    padding-left: 10vh;
    position: absolute;
    height: 0vh;
    right: 10vh;
    left: 0;
    top: 10vh;
    z-index: 1000;
    transition: all 350ms cubic-bezier(0.645, 0.045, 0.355, 1);
    /* easeInOutCubic */
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    /* easeInOutCubic */
  }

  .submenu .logo {
    margin-right: 7.5vw;
    opacity: 0;
  }

  .submenu.active {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: 10vh;
    opacity: 1;
    pointer-events: all;
    height: 8vh;
    border-bottom: solid 1px black;
  }

  .submenu span {
    cursor: pointer;
    color: #000;
    margin-right: 6vmin;
  }

  .submenu .font_ayarkut_ {
    margin-right: 0;
  }

  .submenu span:hover {
    color: #f9f5ec;
  }

  .header .menu-icon {
    display: none;
  }
  .sidebar-nav-icon{
    display: none;
  }
  #sidebar-menu {
    display: none;
  }
}

@media (max-height: 450px) {
  #side-menu {
    transform: translate(0, -600px);
  }
}

@media (min-width: 545px) and (max-width: 768px) {
  #side-menu .options {
    font-size: 60%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  #sidebar-menu {
    width: 40% !important;
  }
}

@media (min-width: 769px) and (max-width: 1400px) {
  .header-contet nav{
    display: none;
  }
  #side-menu {
    display: none;
  }
  .header .submenu {
    display: none;
  }
  .header .header-social-networks {
    display: none;
  }
  .mobile-nav-icon {
    display: none;
  }
  #sidebar-menu {
    font-family: "BasisGrotesqueBold";
    font-size: 2.25rem;
    color: #f9f5ec;
    background: #ff4513;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    width: 30%;
    transform: translate(0, -200vh);
    transition: all 700ms cubic-bezier(0.165, 0.84, 0.44, 1);
    /* easeOutQuart */
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    /* easeOutQuart */  
  }
  #sidebar-menu.active {
    transform: translate(0, 0);
  }
  .sidebar-nav-icon{
    font-size: 40px;
    color: #f9f5ec;
    margin-right: 1vmin;
    cursor: pointer;
  }
  #sidebar-menu .options {
    padding: 0 10% 10%;
  }
  #sidebar-menu .options .link {
    cursor: pointer;
    color: #f9f5ec;
    margin: 10px 0;
  }
  #sidebar-menu .options .link :hover {
    color: #f4e5d2;
    font-size: 2.75rem;
    transition: 800ms;
  }
  #sidebar-menu .options .submenu {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
  }
  #sidebar-menu .options .submenu :hover{
    font-size: 1.5rem
  }
  #sidebar-menu .options .divider {
    height: 10px;
  }
  #sidebar-menu .options .logout {
    border-top: 2px solid black;
    padding-top: 10px;
    font-size: 1.5rem;
  }
  #sidebar-menu .options .logout :hover {
    font-size: 2rem;
  }
  #sidebar-menu .options .header-social-networks {
    font-size: 1.5rem;
  }
  #sidebar-menu .options .header-social-networks :hover{
    font-size: 2rem;
  }
  #sidebar-menu .close-label {
    padding: 6% 10% 0;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
  }
}
