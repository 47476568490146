@font-face {
  font-family: "ActionSansBold";
  src: url(./assets/fonts/ActionSans-Bold.otf);
}

@font-face {
  font-family: "BasisGrotesqueBold";
  src: url(./assets/fonts/basis-grotesque-bold.otf);
}

@font-face {
  font-family: "BasisGrotesqueMedium";
  src: url(./assets/fonts/basis-grotesque-medium.otf);
}

@font-face {
  font-family: "BasisGrotesqueMono";
  src: url(./assets/fonts/basis-grotesque-mono.otf);
}

@font-face {
  font-family: "GranvilleLightItalic";
  src: url(./assets/fonts/Granville_Light_Italic.otf);
}

@font-face {
  font-family: "RobotoBold";
  src: url(./assets/fonts/Roboto-Bold.ttf);
}

@font-face {
  font-family: "RobotoMedium";
  src: url(./assets/fonts/Roboto-Medium.ttf);
}

@font-face {
  font-family: "Ayarkut";
  src: url(./assets/fonts/CSTM_Xprmntl.otf);
}

* {
  box-sizing: border-box;
}

body {
  font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
  scroll-behavior: smooth;
}

html,
body,
#root {
  background: #f9f5ec;
  margin: 0;
  padding: 0;
  width: 100%;
}

.slider {
  height: 100%;
  position: relative;
}

.slider .react-slideshow-container,
.slider .react-slideshow-wrapper.slide,
.slider .react-slideshow-wrapper .images-wrap {
  height: 100%;
}

.alice-carousel__stage {
  transition: all 1500ms cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.alice-carousel__stage-item {
  border-right: solid 1px black;
}

.full-background-cover {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.full-background-contain {
  -webkit-background-size: contain !important;
  -moz-background-size: contain !important;
  -o-background-size: contain !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.orange-btn {
  background: #ff4513;
  border-radius: 5vmin;
  padding: 1vmin;
  height: 5vh;
  text-align: center;
  width: 5vh;
}

.orange-btn p {
  font-size: 1.2vmin;
  margin: 0;
}

a {
  color: #ff4513;
}

@media (max-width: 768px) {
  #desktop {
    display: none;
  }

  #mobile {
    display: flex;
    flex-direction: column;
  }

  #cobertizo {
    width: 100%;
  }

  .alice-carousel {
    height: 90%;
  }

  .alice-carousel > div,
  .alice-carousel .alice-carousel__wrapper,
  .alice-carousel
    .alice-carousel__wrapper
    ul
    .alice-carousel
    .alice-carousel__wrapper
    ul
    li {
    height: 100%;
  }
}

#popup {
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
}

#popup .container {
  background: #f9f5ec;
  font-family: "BasisGrotesqueMedium";
  font-size: 1.1vmin;
  line-height: 1.6vmin;
  padding: 5vmin;
  width: 40%;
}

#popup .container h2 {
  font-size: 1.5vmin;
  text-align: center;
}

#popup .container .button {
  background: #ff4513;
  border-radius: 3vmin;
  cursor: pointer;
  padding: 2vmin 3vmin;
  margin: 0 auto;
}

.hover {
  transform: scale(0);
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* easeOutBack */
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* easeOutBack */
}

.hover.active {
  transform: scale(1);
}

.arrow-btn {
  transform: translateX(0);
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* easeOutBack */
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* easeOutBack */
}

.btn:hover .icon-big-arrow-right.arrow-btn,
.scroll-btn:hover .icon-big-arrow-right.arrow-btn {
  transform: translateX(10px);
}

.btn:hover .icon-big-arrow-left.arrow-btn,
.scroll-btn:hover .icon-big-arrow-left.arrow-btn {
  transform: translateX(-10px);
}

.font_ayarkut_ {
  font-family: "Ayarkut";
}

@media (min-width: 769px) {
  #mobile {
    display: none;
  }

  .arrow-btn {
    font-size: 5vw;
  }

  .hover {
    background: #ff4513;
    border-radius: 50%;
    font-family: "RobotoMedium" !important;
    font-size: 1.5vmin;
    padding: 1vmin;
  }
}

@media (max-width: 768px) {
  #popup .container {
    font-size: 3.3vmin;
    line-height: 4vmin;
    width: 90%;
  }

  #popup .container h2 {
    font-size: 3.8vmin;
  }
}

.back-arrow {
  transform: rotate(180deg);
}
