#program {
    font-family: "GranvilleLightItalic";
  }
  
  #programs .hover {
    /* display: none; */
    width: 8vmin;
    height: 8vmin;
    right: 2vmin;
    position: absolute;
    top: 5vmin;
  }
  
  #programs .hover.active {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  
  #programs .program-title {
    font-family: "BasisGrotesqueBold";
    text-transform: uppercase;
  }
  
  .date-item,
  #programs .program-select,
  #programs .cost .cost-info .title {
    font-family: "GranvilleLightItalic";
  }
  
  .program-filters,
  .cost-info .price,
  #programs .date-item .fulldate-container,
  #main-info .content,
  #programs .apply-btn,
  #programs .cost .cost-info .subtitle {
    font-family: "BasisGrotesqueMedium";
  }
  
  #programs .program-select,
  #programs .includes_desc h3 {
    text-transform: uppercase;
  }
  
  #programs .program-select span {
    display: block;
  }
  
  #programs .arrow-down {
    display: inline-block;
    transform: rotate(90deg);
  }
  
  #programs .scroll-btn,
  #programs .program-filter {
    cursor: pointer;
  }
  
  #programs .btn .icon {
    color: white;
    margin: 0 10px;
  }
  
  #programs .cost .cost-info {
    font-size: 1.7vmin;
  }
  
  @media(max-width:1500px) {
    #programs .cost .cost-info {
      font-size: 1.5vmin;
    }
  }
  
  @media(max-width:1300px) {
    #programs .cost .cost-info {
      font-size: 1.2vmin;
    }
  }
  
  @media(max-width:1100px) {
    #programs .cost .cost-info {
      font-size: 1vmin;
    }
  }
  
  @media(max-width:768px) {
    #programs .cost .cost-info {
      font-size: 2vmin;
    }
  }
  
  @media(max-width:600px) {
    #programs .cost .cost-info {
      font-size: 2.5vmin;
    }
  }
  
  @media (min-width: 769px) {
    .mobile-title {
      display: none;
    }
  
    #programs {
      display: flex;
      font-size: 1.5vmin;
      height: 90vh;
    }

    #programs .programs-image-container {
        background-size: cover!important;
        background-repeat: no-repeat!important;
        background-position: 50%!important;
        height: 100%;
        background: url('../../assets/imgs/programs/img.jpg');
    }

    #programs .programs-gral-image {
        height: 65vh;
    }
  
    #programs #main-info {
      border-right: solid 1px black;
      display: flex;
      flex-direction: column;
      height: 90vh;
      width: 50vw;
    }
  
    #programs #main-info .program-title {
      align-items: center;
      border-bottom: solid 1px black;
      display: flex;
      font-size: 8vmin;
      text-align: center;
      justify-content: center;
      padding: 1vmin;
    }
  
    .desktop-title {
      margin: 10px;
    }
  
    .ppc-title {
      font-size: 4.7vw;
      line-height: 3.3vw !important;
    }
  
    .residence-title {
      font-size: 7vw;
      line-height: 7vw;
    }
  
    #programs .program-selector {
      display: none !important;
      border-bottom: solid 1px black;
      display: flex;
    }
  
    #programs .program-selector .program-select {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      font-size: 2vmin;
      justify-content: center;
      text-align: center;
      padding: 1.5vmin;
      width: 50%;
    }
  
    #programs .program-select.active {
      display: flex;
    }
  
    #programs .content * {
      line-height: 1.1vw !important;
      font-size: 0.8vw;
    }
  
    .program-filter {
      transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
      /* easeInOutCubic */
      transition-timing-function: cubic-bezier(0.645,
          0.045,
          0.355,
          1);
      /* easeInOutCubic */
    }
  
    #programs .program-buttons-container {
      display: flex;
      justify-content: space-around;
      align-content: center;
      width: 100%;
      flex-wrap: wrap;
      gap: 8px;
      margin: 0px;
      font-size: 24px;
      height: 25vh
    }

    #programs .program-button {
        border: solid 1px black;
        border-radius: 2vmin;
        margin-bottom: 10px;
        padding: 1vmin 2vmin;
        text-align: center;
        background-color: #ff3d1d;
        cursor: pointer;
    }

    #programs .program-button:hover {
        color: #f9f5ec;
    }
  
    #programs .program-select.active,
    .program-filter.active,
    .program-filter:hover {
      background: #f4e5d2;
    }
  
    #programs .program-selector .program-select:nth-child(odd) {
      border-right: solid 1px black;
    }
  
    #programs .program-main-content {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      padding: 3vmin 4vmin;
    }
  
    .program-filters,
    .program-filters {
      margin-right: 3vmin;
    }
  
    .program-filter {
      border: solid 1px black;
      border-radius: 2vmin;
      margin-bottom: 10px;
      padding: 1vmin 2vmin;
      text-align: center;
    }
  
    #programs .includes_desc {
      display: flex;
      justify-content: space-between;
    }
  
    #programs .includes_desc .includes {
      margin-right: 1rem;
      max-width: 60%;
    }
  
    #programs .testimonial {
      margin-bottom: 2vmin;
    }
  
    #programs .testimonial span {
      font-size: 2vmin;
      font-style: italic;
    }
  
    #programs .testimonial h3 {
      margin: 0;
    }
  
    #programs .program-subinfo {
      display: flex;
      flex-direction: row;
    }
  
    #programs #secondary-info {
      height: 90vh;
      width: 50vw;
    }
  
    #secondary-info .periods {
      display: flex;
      flex-direction: row;
      height: 90vh;
      width: 100%;
    }
  
    #secondary-info .subinfo-container {
      width: 42vw;
    }
  
    #secondary-info .scroll-section {
      border-left: solid 1px black;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-between;
      width: 8vw;
    }
  
    #programs .scroll-section .scroll-btn {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  
    #programs.scroll-section .scroll-btn img {
      height: 4vh;
      width: 50%;
    }
  
    #programs .scroll-section .scroll-btn-tall {
      border-bottom: solid 1px black;
      height: 30vh;
    }
  
    #programs .scroll-section .scroll-btn-small {
      border-top: solid 1px black;
      height: 10vh;
    }
  
    #programs .scroll-section .scroll-btn-small .hover {
      top: 1vmin;
    }
  
    #programs .description {
      width: 85%;
    }

    #programs .programas-gral-description {
        width: 100%;
        font-family: "BasisGrotesqueMedium";
    }

    #programs .program-gral-description-bottom {
        background-color: #f4e5d2;
        height: 25vh;
        border-top: solid 1px black;
        display: flex;
        justify-content: center;
    }
  
    #programs .subinfo-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  
    #programs .dates-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 65vh;
      padding: 5%;
      width: 100%;
    }
  
    #programs .date-item {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      width: 45%;
    }
  
    #programs .date-item h2 {
      margin: 0;
    }
  
    #programs .date-item .fulldate-container {
      background-color: #f4e5d2;
      padding: 10px 20px;
    }
    
    #programs .bottom-programs-image {
      border-top: solid 1px black;
      display: flex;
      height: 25vh;
      align-items: center;
      justify-content: center;
    }

    #programs .bottom-programs-image {
        height: 65vh;
      }
  
    #programs .bottom-programs-image .image {
      width: 60%;
    }
  
    #programs .subinfo-buttons {
      border-top: solid 1px black;
      display: flex;
      height: 25vh;
    }
  
    #programs .subinfo-buttons .cost {
      background: url("../../assets/imgs/programs/element_01.png");
      border-right: solid 1px black;
      height: 100%;
      width: 50%;
    }
  
    #programs .cost .cost-info {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      padding-bottom: 15px;
    }
  
    #programs .cost .cost-info .title {
      font-size: 2vmin;
    }
  
    #programs .cost .cost-info .subtitle {
      margin-bottom: 10px;
    }
  
    #programs .subinfo-buttons .apply-btn {
      background: url("../../assets/imgs/us/element_02.png");
      height: 100%;
      width: 50%;
    }
  
    #programs .subinfo-buttons .apply-btn.off{
      pointer-events: none;
      cursor: not-allowed;
    }
    
    #programs .subinfo-buttons .apply-btn.off p{
      font-size: 20px;
      text-align: center;
    }
  
    #programs .subinfo-buttons .apply-btn.off .btn span:before {
      display: inline-block;
      text-decoration: none;
    }
  
    #programs .subinfo-buttons .apply-btn .btn {
      align-items: center;
      background-color: #ff3d1d;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 3.5vmin;
      height: 100%;
      text-transform: uppercase;
      width: 100%;
      transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      /* easeOutBack */
      transition-timing-function: cubic-bezier(0.175,
          0.885,
          0.32,
          1.275);
      /* easeOutBack */
    }
  
    #programs .subinfo-buttons .apply-btn .btn:hover {
      font-size: 3.9vmin;
      transform: scale(0.98);
      color: whitesmoke;
    }
  
    #programs .subinfo-buttons .apply-btn .btn span {
      color: whitesmoke;
      margin: 0 10px;
      transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      /* easeOutBack */
      transition-timing-function: cubic-bezier(0.175,
          0.885,
          0.32,
          1.275);
      /* easeOutBack */
    }
  
    #programs .subinfo-buttons .apply-btn .btn:hover span {
      margin: 0 5px;
    }
  
    #secondary-info .full-background-contain {
      height: 70vh;
    }
  
    #secondary-info .full-background-contain .circled {
      border-radius: 50%;
      height: 70vh;
      width: 100%;
    }
  
    #secondary-info .scroll-buttons {
      border-top: solid 1px black;
      display: flex;
      height: 20vh;
    }
  
    #secondary-info .scroll-buttons .image {
      border-right: solid 1px black;
      width: 70%;
    }
  
    #secondary-info .scroll-btn {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  
    #secondary-info .scroll-buttons .scroll-btn {
      position: relative;
      width: 30%;
    }
  
    #secondary-info .scroll-buttons .scroll-btn img {
      height: 50%;
      width: 80%;
    }
  
    #programs .scroll-btn img {
      height: 40%;
      width: 80%;
    }
  
    #programs .scroll-section .scroll-btn {
      position: relative;
    }
  
    #secondary-info .scroll-section .scroll-btn .hover {
      height: 8vmin;
      width: 8vmin;
    }
  
    #secondary-info .scroll-section .scroll-btn .hover.active {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .mobile-bar {
      display: none;
    }
  
    .mentors-container {
      max-height: 65vh;
      overflow: scroll;
    }
  
    #programs .full-background-contain {
      display: flex;
    }
  
    #programs .full-background-sidebar {
      border-left: solid 1px black;
      width: 30%;
    }
  
    #programs .full-background-sidebar .scroll-btn {
      border-bottom: solid 1px black;
      height: 20vh;
    }
  
    #programs .full-background-contain .full-background-cover {
      width: 70%;
    }
  }
  
  @media (max-width: 768px) {
    #programs.form-mobile {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100000000000;
      background: #f9f5ec;
    }
  
    #programs {
      border-bottom: solid 1px black;
    }
  
    #programs .desktop-title {
      display: none;
    }
  
    #programs .hover {
      display: none !important;
    }
  
    #programs #main-info .program-title {
      align-items: center;
      border-bottom: solid 1px black;
      display: flex;
      font-size: 14vmin;
      height: 10%;
      justify-content: center;
      text-align: center;
      padding: 8vmin 0;
      background-color: #f4e5d2;
    }
  
    #programs .program-selector {
      border-bottom: solid 1px black;
      display: flex;
      height: 10%;
    }
  
    #programs .program-selector .program-select {
      cursor: pointer;
      align-items: center;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding: 1.5vmin;
      width: 100%;
    }
  
    #programs .program-select.active,
    .program-filter.active,
    .program-filter:hover {
      background: #f4e5d2;
    }
  
    #programs .program-selector .program-select:nth-child(odd) {
      border-right: solid 1px black;
    }
  
    .program-filters {
      align-items: center;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 8px;
      padding-bottom: 20px;
    }
  
    .program-filter {
      border: solid 1px black;
      border-radius: 2vmin;
      margin: 10px 5px;
      padding: 1vmin 2vmin;
      text-align: center;
    }
  
    #programs .scroll-btn {
      display: none;
    }

    #programs .subinfo-buttons .apply-btn.off {
        pointer-events: none;
        cursor: not-allowed;
    }
    
    #programs .subinfo-buttons .apply-btn.off p{
      font-size: 20px;
      text-align: center;
    }
    
    #programs .subinfo-buttons .apply-btn.off .btn span:before {
    display: inline-block;
    text-decoration: none;
    }

    #programs .program-button {
      border: solid 1px black;
      border-radius: 2vmin;
      margin-bottom: 10px;
      padding: 1vmin 2vmin;
      text-align: center;
      background-color: #ff3d1d;
      cursor: pointer;
      font-size: 7vmin;
    }
  
    #main-info .content {
      padding: 4vmin 8vmin;
      border-bottom: solid 1px black;
    }
  
    #main-info .content,
    #secondary-info {
      font-size: 3.2vmin;
      overflow: auto;
    }
  
    #secondary-info .periods .dates-container {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 5vmin;
      justify-content: center;
    }
  
    #secondary-info .periods .dates-container .date-item {
      box-sizing: border-box;
      padding: 10px;
      text-align: center;
      width: 50%;
    }
  
    #programs .bottom-programs-image {
      display: none;
    }
  
    #programs .subinfo-buttons {
      border-top: solid 1px black;
      display: flex;
      height: 25vh;
    }
  
    #programs .subinfo-buttons .cost {
      background: url("../../assets/imgs/programs/element_01.png");
      border-right: solid 1px black;
      height: 100%;
      width: 50%;
    }
  
    #programs .cost .cost-info {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  
    #programs .cost .cost-info .title {
      font-size: 4.5vmin;
    }
  
    #programs .cost .cost-info .subtitle {
      margin-bottom: 10px;
    }
  
    #programs .subinfo-buttons .apply-btn {
      background: url("../../assets/imgs/us/element_02.png");
      height: 100%;
      width: 50%;
    }
  
    #programs .subinfo-buttons .apply-btn .btn {
      align-items: center;
      background-color: #ff3d1d;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 6vmin;
      height: 100%;
      text-transform: uppercase;
      width: 100%;
    }

    #programs .program-gral-description-bottom {
      display: none;
    }

    #programs .programas-gral-description {
      padding: 6vmin 8vmin;
      font-family: "BasisGrotesqueMedium";
    }
  }
  
  @media (max-width: 600px) {
    #programs .cost .cost-info .subtitle {
      margin-bottom: 3px;
    }
  
    #programs .cost .cost-info {
      padding-bottom: 17px;
    }
  }
  
  /*
    
    #main-info {
      height: 100%;
    }
    #main-info .program-main-content {
      height: 80%;
      overflow: hidden;
    }
    
    .mobile-bar {
      border-top: solid 1px black;
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 22%;
    }
    #programs .apply-btn {
      background: url('../../assets/imgs/us/element_02.png');
      height: 100%;
      width: 50%;
    }
    #programs .apply-btn .btn {
      align-items: center;
      background-color: #ff3d1d;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      font-size: 4vmin;
      height: 100%;
      text-transform: uppercase;
      width: 100%;
    }
    #programs .scroll-btn {
      border-left: solid 1px black;
      align-items: center;
      display: flex;
      justify-content: center;
      width: 50%;
      height: 100%;
    }
    #programs .scroll-btn img {
      height: 50%;
      width: 60%;
    }
    #secondary-info {
      display: none;
    }
  
  */
  