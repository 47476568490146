#gallery .title {
  background: #f4e5d2;
  font-family: 'BasisGrotesqueBold';
}

#gallery  {
  font-family: 'GranvilleLightItalic';
}

#gallery .background > div * {
  font-family: 'BasisGrotesqueMedium';
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
#gallery .scroll-btn {
  cursor: pointer;
}

#gallery .content-image {
  align-items: flex-end;
  display: flex;
  justify-content: center;
}

#gallery .footpage {
  background: rgba(0, 0, 0, 0.4);
  color: white;
  text-align: center;
  height: 10vh;
  width: 100%;
}

#gallery .slide-image {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  height: 100%;
}

#gallery .slide-image video {
  background: black;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
}

@media (min-width: 769px) {
  .container-maps .content-image {
    display: none;
  }
  .border-top {
    border-top: solid 1px black;
  }
  .border-bottom {
    border-bottom: solid 1px black;
  }
  .border-left {
    border-left: solid 1px black;
  }
  .background {
    background: #f9f5ec;
  }
  #gallery {
    display: flex;
    flex-direction: row;
    height: 90vh;
    width: 100%;
  }
  #gallery .left-side,
  #gallery .right-side {
    width: 10vw;
  }
  #gallery .left-side {
    border-right: solid 1px black;
  }
  #gallery .right-side {
    border-left: solid 1px black ;
  }
  #gallery .main-content {
    width: 80vw;
  }
  #gallery .title {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70vh;
    position: relative;
    text-transform: uppercase;
  }
  #gallery .right-side .title img {
    display: none;
  }
  #gallery .title > div {
    transform-origin: 50% 50%;
    transform: rotate(-90deg);
  }
  #gallery .title h1 {
    font-size: 8vmin;
    margin: 0;
  }
  #gallery .scroll-btn,
  #gallery .block-right {
    box-sizing: border-box;
    height: 10vh;
  }
  #gallery .lines {
    background: url(../../assets/imgs/us/element_02.png);
    height: 10vh;
  }
  #gallery .main-content .container {
    height: 70vh;
    position: relative;
  }
  #gallery .content-image {
    height: 100%;
    width: 100%;
  }
  #gallery .container-maps {
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  #gallery .container-item {
    height: 35vh;
    width: 50%;
  }
  #gallery .background {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 2vmin 4vmin;
  }
  #gallery .background > div * {
    font-size: 1.6vmin;
    line-height: 2vmin;
  }
  #gallery .pin-container {
    height: 5vh;
    margin: 3vmin;
    position: relative;
    width: 5vh;
  }
  #gallery iframe {
    height: 100%;
    width: 100%;
  }
  #gallery .pin-container .orange-btn {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    right: -50%;
    top: -50%;
  }
  #gallery .left-side .scroll-btn .arrow {
    transform: rotate(-180deg);
  }
  #gallery .left-side .scroll-btn .hover {
    /* display: none; */
    position: absolute;
    height: 8vmin;
    left: 10%;
    top: 10%;
    width: 8vmin;
  }
  #gallery .left-side .scroll-btn,
  #gallery .right-side .scroll-btn{
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
  }
  #gallery .scroll-btn img {
    width: 50%;
  }
  #gallery .right-side .scroll-btn .hover {
    /* display: none; */
    position: absolute;
    height: 8vmin;
    right: 10%;
    top: 10%;
    width: 8vmin;
  }
  #gallery .left-side .scroll-btn .hover.active,
  #gallery .right-side .scroll-btn .hover.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #gallery .slider-buttons {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    height: 10vh;
  }
  #gallery .archive-title {
    align-items: center;
    display: flex;
    font-size: 8vmin;
  }
  #gallery .archive-button {
    border: solid 1px black;
    cursor: pointer;
    border-radius: 4vmin;
    margin: 0 1vmin;
    padding: 0.5vmin 2vmin;
    transition: all 250ms cubic-bezier(0.645, 0.045, 0.355, 1.000); /* easeInOutCubic */
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000); /* easeInOutCubic */
  }
  #gallery .archive-button:hover {
    background: #ff4513;
  }
  #gallery .archive-button span {
    font-size: 2vmin;
    line-height: 0;
  }

}

@media (max-width: 768px) { 
  #gallery {
  }
  #gallery .left-side {
    height: 15%;
  }
  #gallery .left-side .scroll-btn {
    display: none;
  }
  #gallery .left-side .title {
    align-items: center;
    display: flex;
    font-size: 7vmin;
    height: 100%;
    justify-content: center;
    text-transform: uppercase;
    width: 100%;
  }
  #gallery .right-side {
    display: none;
  }
  #gallery .main-content {
    height: 70vh;
  }
  #gallery .map-item {
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    height: 30%;
  }
  #gallery .map-item .pin-container {
    display: none;
  }
  #gallery .map-item .content-image {
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    border-radius: 50%;
    display: flex;
    flex-wrap: wrap;
    height: 24vh;
  }
  #gallery .map-item .content-image .container-item {
    height: 50%;
    width: 50%;
  }
  #gallery .map-item .item1 {
    border-right: solid 1px black;
    border-bottom: solid 1px black;
  }
  #gallery .map-item .item2 {
    border-bottom: solid 1px black;
  }
  #gallery .map-item .item3 {
    border-right: solid 1px black;
  }
  #gallery .background  {
    padding: 4vmin;
  }
  #gallery .background > div * {
    font-size: 2.9vmin;
    line-height: 3.6vmin;
  }
  #gallery .hover {
    display: none;
  }
  #gallery .scroll-btn {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 50%;
  }
  #gallery .scroll-btn:nth-child(1) {
    border-left: solid 1px black;
  }
  #gallery .scroll-btn img {
    height: 80%;
  }
  #gallery .container {
    height: 80%;
  }
  #gallery .slider,
  #gallery .content-image {
    height: 100%;
  }
  #gallery .slider-buttons {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    height: 10vh;
  }
  #gallery .archive-title {
    align-items: center;
    display: flex;
    font-size: 8vmin;
  }
  #gallery .archive-button {
    border: solid 1px black;
    cursor: pointer;
    border-radius: 4vmin;
    margin: 0 1vmin;
    padding: 1vmin 4vmin;
  }
  #gallery .archive-button:hover {
    background: #ff4513;
  }
  #gallery .archive-button span {
    font-size: 10vmin;
    line-height: 0;
  }
}

@media (min-width: 769px) {
  #gallery .slide-image video {
    object-fit: cover;
  }
}