#location .title {
  background: #f4e5d2;
  font-family: 'BasisGrotesqueBold';
}

#location  {
  font-family: 'GranvilleLightItalic';
}

#location .background > div * {
  font-family: 'BasisGrotesqueMedium';
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
#location .scroll-btn {
  cursor: pointer;
}

@media (min-width: 769px) {
  .container-maps .content-image {
    display: none;
  }
  .border-top {
    border-top: solid 1px black;
  }
  .border-bottom {
    border-bottom: solid 1px black;
  }
  .border-left {
    border-left: solid 1px black;
  }
  .background {
    background: #f9f5ec;
  }
  #location {
    display: flex;
    flex-direction: row;
    height: 90vh;
    width: 100%;
  }
  #location .left-side,
  #location .right-side {
    width: 10vw;
  }
  #location .left-side {
    border-right: solid 1px black;
  }
  #location .right-side {
    border-left: solid 1px black ;
  }
  #location .main-content {
    width: 80vw;
  }
  #location .title {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70vh;
    position: relative;
    text-transform: uppercase;
  }
  #location .right-side .title img {
    display: none;
  }
  #location .title > div {
    transform-origin: 50% 50%;
    transform: rotate(-90deg);
  }
  #location .title h1 {
    font-size: 8vmin;
    margin: 0;
  }
  #location .scroll-btn,
  #location .block-right {
    box-sizing: border-box;
    height: 10vh;
  }
  #location .lines {
    background: url(../../assets/imgs/us/element_02.png);
    height: 10vh;
  }
  #location .main-content .container {
    height: 70vh;
    position: relative;
  }
  #location .content-image {
    border: solid 1px black;
  }
  #location .content-image,
  #location .content-image .slider-fade {
    border-radius: 50%;
    height: 100%;
    overflow: hidden;
    width: 100%;
  }
  #location .content-image .slider-fade,
  #location .content-image .slider-fade div {
    height: 100%;
  }
  #location .container-maps {
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000;
  }
  #location .container-item {
    height: 35vh;
    width: 50%;
  }
  #location .background {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 2vmin 4vmin;
  }
  #location .background > div * {
    font-size: 1.6vmin;
    line-height: 2vmin;
  }
  #location .pin-container {
    height: 5vh;
    margin: 3vmin;
    position: relative;
    width: 5vh;
  }
  #location iframe {
    height: 100%;
    width: 100%;
  }
  #location .pin-container .orange-btn {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    right: -50%;
    top: -50%;
  }
  #location .left-side .scroll-btn .arrow {
    transform: rotate(-180deg);
  }
  #location .left-side .scroll-btn .hover {
    display: none;
    position: absolute;
    height: 8vmin;
    left: 10%;
    top: 10%;
    width: 8vmin;
  }
  #location .left-side .scroll-btn,
  #location .right-side .scroll-btn{
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
  }
  #location .scroll-btn img {
    width: 50%;
  }
  #location .right-side .scroll-btn .hover {
    font-family: 'RobotoMedium' !important;
    /* display: none; */
    position: absolute;
    height: 8vmin;
    right: 10%;
    top: 10%;
    width: 8vmin;
  }
  #location .left-side .scroll-btn .hover.active,
  #location .right-side .scroll-btn .hover.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #location .slide-image img {
    display: none;
  }
}

@media (max-width: 768px) { 
  #location {
    border-bottom: solid 1px black;
  }
  #location .left-side {
    height: 15%;
  }
  #location .left-side .scroll-btn {
    display: none;
  }
  #location .left-side .title {
    align-items: center;
    display: flex;
    font-size: 7vmin;
    height: 100%;
    justify-content: center;
    text-transform: uppercase;
    width: 100%;
  }
  #location .right-side {
    border-top: solid 1px black;
    display: flex;
    flex-direction: row-reverse;
    height: 15vh;
  }
  #location .right-side .title {
    align-items: center;
    background: none;
    border-right: solid 1px black;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 50%;
  }
  #location .right-side .title img {
    height: 70%;
  }
  #location .right-side .scroll-btn.border-bottom {
    display: flex;
    height: 100%;
    width: 50%;
  }
  #location .right-side .scroll-btn.border-top {
    display: none;
  }
  #location .main-content {
    height: 70%;
  }
  #location .map-item {
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    height: 30%;
  }
  #location .map-item .pin-container {
    display: none;
  }
  #location .map-item .content-image {
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    border-radius: 50%;
    background-size: cover !important;
    background-size: 100% 100% !important;
    display: flex;
    flex-wrap: wrap;
    height: 35vh;
  }
  #location .map-item .content-image .container-item {
    height: 50%;
    width: 50%;
  }
  #location .map-item .item1 {
    border-right: solid 1px black;
    border-bottom: solid 1px black;
  }
  #location .map-item .item2 {
    border-bottom: solid 1px black;
  }
  #location .map-item .item3 {
    border-right: solid 1px black;
  }
  #location .background  {
    padding: 4vmin;
  }
  #location .background > div * {
    font-size: 2.9vmin;
    line-height: 3.6vmin;
  }
  #location .hover {
    display: none;
  }
  #location .scroll-btn {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  #location .scroll-btn img {
    height: 80%;
  }
  #location .back-arrow {
    transform: rotate(0);
  }
  #location .content-image-mobile {
    z-index: 20;
    overflow: hidden;
    position: relative;
  }
  #location .map-item .content-image div {
    height: 100%;
    width: 100%;
  }
  #location .grid {
    display: flex;
    flex-wrap: wrap;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000;
  }
  #location .scroll-btn {
    background: rebeccapurple;
    background: url(../../assets/imgs/us/element_02.png);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    height: 100%;
    width: 100%;
  }
  #location .scroll-btn .arrow {
    display: none;
  }
  #location .slide-image img {
    height: 100%;
    width: 100%;
  }
}