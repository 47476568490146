#us .slide-image {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  height: 100%;
}

#us .slider-buttons {
  bottom: 4vmin;
  left: 4vmin;
  position: absolute;
}
#us .btn {
  cursor: pointer;
}
#us .slide-btn {
  border: solid 1px #f9f5ec;
  border-radius: 2vmin;
  color: #f9f5ec;
  font-size: 2vmin;
  font-family: 'RobotoMedium';
  margin-right: 1vmin;
  padding: 1vmin 3vmin;
}
#us .slide-btn.active {
  border: solid 1px #f9f5ec;
  background: #f9f5ec;
  color: black;
}

#us .slider-block {
  position: relative;
}
#us .single-image-block {
  background: #ff3d1d;
}
#us .info-image {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  position: relative;
}
#us .hover {
  /* display: none; */
  position: absolute;
  height: 8vmin;
  right: 10%;
  width: 8vmin;
  display: flex;
  align-items: center;
  justify-content: center;
}
#us .hover.active {
  display: flex;
  align-items: center;
  justify-content: center;
}
#us .info-title {
  font-family: 'BasisGrotesqueBold';
  text-transform: uppercase;
  margin: 0;  
}
#us .info-description-text .text {
  font-family: 'BasisGrotesqueMedium';
}
#us .info-image img {
  width: 50%;
}
#us .scroll-btn,
#us .slide-btn {
  cursor: pointer;
}
#us .image {
  -webkit-background-size: contain !important;
  -moz-background-size: contain !important;
  -o-background-size: contain !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}
#us .single-image-block .image {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  border-top-left-radius: 100vh;
  border-top-right-radius: 100vh;
  height: 100%;
  width: 100%;
}
#us .image-bottom {
  align-items: center;
  display: flex;
  justify-content: center;
}
#us .image-bottom img {
  width: 100%;
}

#us .founders {
  text-transform: uppercase;
}

#us .founders h3 {
  font-family: 'GranvilleLightItalic';
  margin: 0 0 0.5vmin 0;
}

#us .founders span {
  font-family: 'BasisGrotesqueBold';
}

#us .founders-container .image img {
  max-height: 100%;
  width: 100%;
}

@media (min-width: 769px) {
  #us {
    display: flex;
    flex-direction: row;
    height: 90vh;
  }
  #us .gallery-block {
    border-right: solid 1px black;
    display: flex;
    flex-direction: column;
    width: 40vw;
  }
  #us .slider-block {
    height: 60vh;
  }
  #us .single-image-block {
    height: 30vh;
  }
  #us .info-block {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  #us .info-header {
    border-bottom: solid 1px black;
    display: flex;
    flex-direction: row;
    height: 30vh;
  }
  #us .info-title {
    align-items: center;
    border-right: solid 1px black;
    display: flex;
    justify-content: center;
    width: 52vw;
  }
  #us .info-title h1 {
    font-size: 8vw;
  }
  #us .info-image {
    width: 8vw;
  }
  #us .info-description {
    display: flex;
    height: 60vh;
  }
  #us .info-description-text {
    border-right: solid 1px black;
    display: flex;
    flex-direction: column;
    width: 52vw;
  }
  #us .info-description-text .single-image-block,
  #us .bottom-arrow .arrow {
    display: none;
  }
  #us .info-description-text .container {
    display: flex;
    flex-direction: row;
    height: 50vh;
  }
  #us .info-description-text .text {
    align-items: center;
    border-right: solid 1px black;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-size: 1.5vmin;
    justify-content: center;
    padding: 0 4vmin;
    width: 50%;
  }
  #us .info-description-text .text p {
    line-height: 2.4vmin;
  }
  #us .info-description-text .founders-container {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  #us .founders-container .image {
    border-bottom: solid 1px black;
    height: 15vh;
  }
  #us .founders-container .founders {
    align-items: center;
    background-color: #f4e5d2;
    display: flex;
    height: 35vh;
    flex-direction: column;
    font-size: 1.5vmin;
    justify-content: center;
  }
  #us .founders-container .founder {
    margin-bottom: 1vmin;
  }
  #us .founders-container .founder:last-child {
    margin-bottom: 0;
  }
  #us .founders-container .founders h3 {
    margin: 0;
    font-size: 1.6vmin;
    line-height: 1.6vmin;
  }
  #us .founders-container .founders span {
    font-size: 1vmin;
    line-height: 1vmin;
  }
  #us .founder {
    box-sizing: border-box;
    max-width: 50%;
    text-align: center;
    width: 50%;
  }
  #us .info-image-desc {
    align-items: flex-end;
  }
  #us .bottom-arrow {
    border-top: solid 1px black;
    max-height: 10vh;
    height: 10vh;
  }
}

@media (max-width: 768px) {
  #us {
    border-bottom: solid 1px black;
    display: flex;
    flex-direction: column;
  }
  #us .info-block {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  #us .info-description {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
  }
  #us .info-description-text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  #us .info-header {
    align-items: center;
    border-bottom: solid 1px black;
    display: flex;
    flex-direction: column;
    font-size: 7.5vmin;
  }
  #us .info-description-text .single-image-block {
    height: 150px;
  }
  #us .info-description-text .container {
    flex-grow: 1;
    font-size: 3.2vmin;
  }
  #us .bottom-arrow.image-bottom {
    height: 100px;
  }
  #us .bottom-arrow.image-bottom img {
    height: 100%;
  }
  #us .info-description-text .text {
    border-bottom: solid 1px black;
    padding: 6vmin 8vmin;
  }
  #us .info-description-text .founders-container {
    text-align: center;
    padding: 6vmin 8vmin;
  }
  #us .info-header .info-image,
  #us .info-image.bottom-arrow.btn,
  #us .bottom-arrow.image-bottom .arrow {
    display: none;
  }
}