/* Header container */
#allies .info-block .info-header-container .info-title {
  font-family: "BasisGrotesqueBold";
  text-transform: uppercase;
  text-align: center;
  font-size: 5vmin;
}

#allies .info-block .info-header-container .info-description {
  font-family: "BasisGrotesqueMedium";
  font-size: 2.4vmin;
}

/* Patronos container */
#allies .info-block .patronato {
  text-align: center;
  background: #f4e5d2;
}

#allies .info-block .patronato h2{
  margin: 0;
}

#allies .info-block .patronato .patronos-container {
  font-family: 'BasisGrotesqueMedium';
}

/* Allies container */
#allies .info-block .allies-container {
  display: grid;
  text-align: center;
}

#allies .info-block .allies-container .allies-column{
  padding: 2vmin;
}

#allies .info-block .allies-container .allies-column.odd {
  background: #f4e5d2;
}

#allies .info-block .allies-container .allies-column .allies-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

#allies .info-block .allies-container .ally h4, h2{
  font-family: "BasisGrotesqueBold";
  margin: 1vmin 0;
  text-transform: uppercase;
}

#allies .info-block .allies-container .ally p{
  font-size: 1.75vmin;
  font-family: "BasisGrotesqueMedium";
}

@media (min-width: 769px) {
  #allies {
    display: flex;
    height: 90vh;
    width: 100%;
  }

  #allies .info-block {
    max-width: 92%;
  }

  /* Header container */
  #allies .info-block .info-header-container {
    display: flex;
    align-items: center;
    padding: 0 3vmin;
    border-bottom: 1px solid black;
  }

  #allies .info-block .info-header-container .info-title {
    padding-right: 3vmin;
    border-right: 1px solid black;
  }

  #allies .info-block .info-header-container .info-description {
    padding-left: 3vmin;
  }

  /* Patronos container */
  #allies .info-block .patronato {
    display: flex;
    padding: 0 2vmin;
    border-bottom: 1px black solid;
  }

  #allies .info-block .patronato h2{
    padding-right: 20px;
    align-self: center;
  }

  #allies .info-block .patronato .patronos-container .patrono {
    min-width: 208px;
  }

  #allies .info-block .patronato .patronos-container {
    display: flex;
    padding-left: 20px;
    gap: 20px;
    border-left: 1px black solid;
    align-items: center;
    overflow-x: auto;
  }

  /* Allies container */
  #allies .info-block .allies-container {
    grid-template-columns: auto auto auto;
  }

  #allies .info-block .allies-container .allies-column {
    height: 44vh;
    overflow-y: auto;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
  }

  #allies .info-block .allies-container .ally{
    padding: 1vmin 0vmin;
  }

  /* Arrow button section */
  #allies .info-arrows {
    border-left: 1px solid #000;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 8%;
    background: #f4e5d2;
  }

  #allies .info-arrows .scroll-btn {
    cursor: pointer;
    position: relative;
    text-align: center;
    height: 10vh;
    background-color: #f9f5ec;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: 1px solid #000;
  }

  #allies .scroll-btn img {
    width: 50%;
  }

  #allies .info-arrows .scroll-btn .arrow-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #allies .info-arrows .scroll-btn .hover {
    position: absolute;
    height: 8vmin;
    right: 10%;
    top: 10%;
    width: 8vmin;
  }

  #allies .info-arrows .scroll-btn .hover.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  #allies {
    margin-bottom: 20px;
  }


  /* Header container */
  #allies .info-block .info-header-container .info-title {
    font-size: 7vmin;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    background-color: #f4e5d2;
  }

  #allies .info-block .info-header-container .info-description {
    padding: 3vmin 4vmin;
  }

  /* Patronato container */
  #allies .info-block .patronato {
    border-top: 1px solid #000;
    padding: 2vmin;
  }

  /* Allies container */
  #allies .info-block .allies-container .allies-column{
    border-top: 1px solid #000;
  }

  #allies .info-block .allies-container .ally{
    padding: 2vmin 0vmin;
  }

  #allies .info-block .allies-container .ally p{
    font-size: 2.5vmin;
  }


  /* Arrow button section */
  #allies .info-arrows {
    display: none;
  }
}