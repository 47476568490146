#home {
  background: #f9f5ec;
}

#home .home-title h1 {
  font-family: 'BasisGrotesqueBold';
  text-transform: uppercase;
  margin: 0;
}

#home .home-title span {
  font-family: 'GranvilleLightItalic';
  text-transform: uppercase;
}

#home .slide-image {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  height: 100%;
}

#home .slide-image video {
  background: black;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
}

#home .slider-buttons {
  bottom: 8vmin;
  left: 4vmin;
  position: absolute;
}

#home .slide-btn {
  border: solid 1px #f9f5ec;
  border-radius: 2vmin;
  background-color: rgba(0, 0, 0, 0.3);
  color: #f9f5ec;
  font-size: 2vmin;
  font-family: 'RobotoMedium';
  margin-right: 1vmin;
  padding: 1vmin 3vmin;
  transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  /* easeInOutCubic */
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  /* easeInOutCubic */
}

#home .slide-btn.active,
#home .slide-btn:hover {
  border: solid 1px #f9f5ec;
  background: #f9f5ec;
  color: black;
}

#home .scroll-btn {
  background: #f4e5d2;
  cursor: pointer;
}

#home .scroll-btn img {
  transform: translateX(0);
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
  /* easeOutBack */
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
  /* easeOutBack */
}

#home .scroll-btn:hover img {
  transform: translateX(10px);
}

#home .slide-btn {
  cursor: pointer;
}

#home .home-scroll .scroll-btn {
  align-items: center;
  display: flex;
  position: relative;
}

#home .home-scroll .oblongs {
  margin-right: 5%;
  height: 100%;
  width: 50%;
}

#home .home-scroll .scroll-btn .arrow {
  height: 50%;
  width: 40%;
}

.images-wrap {
  transition: all 1500ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  /* easeInOutCubic */
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  /* easeInOutCubic */
}

.home-title {
  position: relative;
}

.home-popup {
  background: #ff4513;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  cursor: pointer;
  left: 0;
  right: 0;
  top: 0;
  /* font-size: 2vw; */
  width: 100%;
  overflow: hidden;
  z-index: 99;
}

.home-popup.beca {
  top: 3rem;
}

.home-popup.beca .scroll{
  animation: move 11s 1s infinite linear;
}

.scroll {
  height: 100%;
  white-space: nowrap;
  animation: move 10s 1s infinite linear;
  margin: 0;
  font-size: 0;
  display: inline-block;
}


.home-popup p {
  font-size: 50px;
  display: inline-block;
  min-width: 100vw;
  margin: 0;
  padding: 0;
  color: white;
}

.home-popup span {
  display: inline-block;
}


@keyframes move {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-50%);
  }
}

.home-popup p {
  color: white;
  font-size: 1.4rem;
  line-height: 1rem;
  text-align: center;
  font-family: 'BasisGrotesqueMedium';
  padding: 10px 0;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
}

@media(max-width:1200px) {
  .home-popup p {
    font-size: 0.8rem;
  }
}

@media (min-width: 769px) {
  #home .slide-image video {
    object-fit: cover;
  }

  #home {
    display: flex;
    flex-direction: row;
    height: 90vh;
  }

  #home .home-content {
    border-right: solid 1px black;
    width: 70vw;
  }

  #home .home-content .home-title {
    align-items: center;
    border-bottom: solid 1px black;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 30vh;
    justify-content: center;
    max-height: 30vh;
  }

  #home .home-title h1 {
    font-size: 10vw;
    line-height: 10vw;
  }

  #home .home-title span {
    font-size: 4vmin;
  }

  #home .home-content .home-slider {
    height: 60vh;
    min-height: 60vh;
    position: relative;
  }

  #home .home-scroll {
    width: 30vw;
  }

  #home .home-scroll .home-image,
  #home .home-scroll .scroll-btn {
    box-sizing: border-box;
    height: 30vh;
    max-height: 30vh;
  }

  #home .home-scroll .home-image {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  #home .home-scroll .home-image img {
    width: 60%;
  }

  #home .home-scroll .coords-image {
    border-bottom: solid 1px black;
  }

  #home .home-scroll .coords-image img {
    width: 90%;
  }

  #home .home-scroll .scroll-btn {
    border-bottom: solid 1px black;
  }

  #home .home-scroll .scroll-btn .hover {
    /* display: none; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 9vmin;
    position: absolute;
    right: 10%;
    top: 20%;
    width: 9vmin;

  }

  #home .home-scroll .scroll-btn .hover.active {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2vmin;
  }
}

@media (max-width: 768px) {


  .home-popup .icon-star-1 {
    font-size: 15vmin !important;
    line-height: 7.5vmin !important;
    text-shadow: 2px 0 red, -2px 0 red, 0 2px red, 0 -2px red, 1px 1px red, -1px -1px red, 1px -1px red, -1px 1px red;
    -webkit-text-stroke: 1px #ff4513;
  }

  .home-popup p {
    font-size: 0.65rem;
  }

  #home .slider-buttons {
    bottom: 16vmin;
  }

  #home {
    border-bottom: solid 1px black;
    padding-top: 85px;
  }

  #home .home-content {
    display: flex;
    flex-direction: column;
    height: 65vh;
    justify-content: space-between;
  }

  #home .home-title {
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    padding: 3vh;
    text-align: center;
  }

  #home .home-title h1 {
    font-size: 14vmin;
  }

  #home .home-title span {
    font-size: 4vmin;
  }

  #home .home-slider {
    height: 100%;
    position: relative;
  }

  #home .home-scroll {
    height: 20vh;
  }

  #home .home-scroll .scroll-btn {
    height: 100%;
  }

  #home .home-scroll .scroll-btn .hover {
    display: none;
  }

  #home .home-image {
    display: none;
  }

  #home .home-scroll .scroll-btn .arrow {
    width: 10vh;
    transform: rotate(90deg);
    margin: 0 auto;
  }
}