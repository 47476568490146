.form {
  font-family: 'BasisGrotesqueMedium';
}

.form h1 {
  font-family: 'BasisGrotesqueBold';
  text-transform: uppercase;
}

.form label {
  font-family: 'BasisGrotesqueBold';
}

.input-field {
  display: flex;
  flex-direction: column;
}

.input {
  background-color: #f4e5d2;
  font-family: 'BasisGrotesqueMedium';
  border-radius: 2vmin;
  border: none;
  padding: 1vmin 2vmin;
  outline: none;
}

.input:focus {
  border: solid 1px black;
}

.select-input {
  position: relative;
  width: 15vw;
}

.select-data {
  background-color: #f4e5d2;
  border-radius: 2vmin;
  max-height: 15vh;
  overflow: auto;
  position: absolute;
  top: 80%;
  left: 0;
  right: 2vmin;
}

.select-item {
  padding: 1vmin 0;
  text-align: center;
}

.select-item:hover {
  background-color: #ff3d1d;
}

.error {
  color: crimson;
}

.form-buttons {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.form-buttons .btn {
  border: solid 1px black;
  border-radius: 4vmin;
  cursor: pointer;
  padding: 2vmin 4vmin;
  margin-right: 2vmin;
  transition: all 250ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  /* easeInOutCubic */
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  /* easeInOutCubic */
}

.form-buttons .btn.back-btn:hover {
  background-color: #dd381e;
  transform: scale(0.98);
}

.form-buttons .send-btn {
  background-color: #ff3d1d;
}

.form-buttons .send-btn:hover {
  background-color: #dd381e;
  transform: scale(0.98);
}

.center-form {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.w-100 {
  width: 100%;
}

@media (min-width: 769px) {
  .form-divider {
    background: url(../../../assets/imgs/patron.png);
    width: 20%;
  }

  .border-right {
    border-right: solid 1px black;
  }

  .border-left {
    border-left: solid 1px black;
  }

  .form {
    display: flex;
    flex-direction: column;
    font-size: 1.5vmin;
    width: 60%;
  }

  .error {
    font-size: 2vmin !important;
    margin-top: 1vmin;
  }

  .form span {
    font-size: 1.3vmin;
  }

  .form h1 {
    font-size: 8vmin;
    text-align: center;
  }

  .form-row {
    display: flex;
    flex-direction: row;
  }


  .input {
    background-color: #f4e5d2;
    border-radius: 2vmin;
    border: none;
    outline: none;
    padding: 1vmin 2vmin;
  }

  .input-number {
    max-width: 7vw;
  }

  .form-row {
    margin-bottom: 3vmin;
  }

  .date-row {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .input-field .input,
  .date-row span {
    margin-top: 1vmin;
    margin-right: 2vmin;
  }

  .file-input {
    width: 10vw;
  }

  .year-input {
    max-width: 6vw;
    width: 15vw;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  .center-form h1 {
    font-size: 4vmin;
  }

}

@media (max-width: 768px) {
  .center-form {
    font-size: 4vmin;
  }

  .form h1 {
    text-align: center;
  }

  .form-container {
    margin: 0 auto;
    width: 80%;
  }

  .input-field {
    margin-bottom: 3vmin;
    width: 50%;
  }

  .mobile-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }

  .select-input {
    width: 100%;
  }

  .select-data {
    max-height: 30vh;
  }

  .files-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .files-row .input-field {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    padding: 10px 0;
  }

  .files-row .input-field span {
    font-size: 3.8vmin;
  }

  .files-row .input-field>div {
    margin: 5px 10px 0 0;
    text-align: center;
  }

  .mobile-row-input {
    display: flex;
  }

  .mobile-row-input .input-field {
    margin-right: 10px;
    width: 50%;
  }
}

/*

<div className='form-row'>
        <label>Fecha de nacimiento</label>
        <div className='input-field'>
          <input />
        </div>
        <span>/</span>
        <div className='input-field'>
          <input />
        </div>
        <span>/</span>
        <div className='input-field'>
          <input />
        </div>
      </div>

*/