#donate #left-content .title {
  font-family: "BasisGrotesqueBold";
  text-transform: uppercase;
  border-bottom: 1px solid #000;
  text-align: center;
}

#donate #left-content .description {
  padding: 3vmin 4vmin;
  font-family: "BasisGrotesqueMedium";
  font-size: 2.4vmin;
}

#donate #right-content .form-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

#donate #right-content .form-content iframe{
  border: none;
  max-height:none !important;
  max-width: 425px;
}

@media (min-width: 769px) {
  #donate {
    display: flex;
    height: 90vh;
    width: 100%;
  }
  
  #donate #left-content {
    border-right: 1px solid #000;
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  #donate #left-content .title {
    font-size: 8vmin;
    padding: 1vmin;
  }

  #donate #left-content .description-image {
    background-color: #f4e5d2;
    border-top: 1px solid #000;
    text-align: center;
    padding: 5vh 0;
  }

  #donate #right-content {
    display: flex;
    width: 50vw;
  }

  #donate #right-content .form-content {
    width: 42vw;
    padding: 3vh;
  }

  #donate #right-content .form-content .form-image {
    padding-bottom: 3vh;
  }

  #donate #right-content .scroll-section {
    border-left: 1px solid #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 8vw;
    background: #f4e5d2;
  }

  #donate #right-content .scroll-btn{
    cursor: pointer;
    position: relative;
    text-align: center;
    height: 10vh;
    background-color: #f9f5ec;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #donate #right-content .back-btn {
    border-top: 1px solid #000;
  }

  #donate #right-content .border-bottom {
    border-bottom: solid 1px black;
  }

  #donate .scroll-btn img {
    width: 50%;
  }

  #donate #right-content .scroll-btn .arrow-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #donate #right-content .scroll-btn .hover {
    position: absolute;
    height: 8vmin;
    right: 10%;
    top: 10%;
    width: 8vmin;
  }

  #donate #right-content .scroll-btn .hover.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) { 
  #donate #left-content .title {
    font-size: 14vmin;
    border-top: 1px solid #000;
    background-color: #f4e5d2;
    padding: 8vmin 0;
  }

  #donate #left-content .description-image {
    display: none;
  }

  #donate #right-content .form-content {
    padding-bottom: 3vh;
  }

  #donate #right-content .scroll-section{
    display: none;
  }

  #donate #right-content .form-content iframe{
    height: 768px;
  }
}
